// Array made from https://arraythis.com/
export const remindersList = [
    "Imagining who I want to be in a year, and trying to be that person every day (falling short but getting closer over time), including breaking that down into smaller steps",
    "Similar to reminding myself that the day is almost over, in the morning remind myself that it’s almost noon (because reminding myself that the end of the day will come sooner than expected feels too distant to have its full effect)",
    "All I need to remember is how precious an hour is, and making sure that I’m going as fast I can every hour to value it",
    "The limiting factor to my success is not the quality of the work but how fast I do the work",
    "One of the biggest things holding me back is not going for the slightly-worse-but-MUCH-faster option",
    "Just like with stressors I always look back at how easy it was to get through, when pushing myself each day I’ll look back and wish I pushed myself more",
    "“Doing nothing half way” taken literally – all in on everything all the time",
    "Extreme way to think about time: every block of time should be (or have a strong chance at being) the most important thing I’ll ever do",
    "Don’t think about tasks in terms of hours, think about them in portions of a day (e.g. it’s not four hours, it’s half a day)",
    "The single most important thing I should make sure to do might be only having one thing in my head at a time – that includes admin tasks…which means I shouldn’t group admin tasks but instead somehow take them one at a time – maybe doing one right before a deep work task",
    "I get drained by nighttime, which means I’m more frazzled when prioritizing tasks, which means I should decide what the rest of my day will be like by 6pm (including prep tasks)",
    "I don’t have to rush all the way, I only need to consistently rush slightly more than baseline",
    "Identify how long the fastest person would take on the task, then make that happen",
    "It’s not just about staying in the present, it’s also about being in the present without any judgment of anything – it’s a completely different way of going through the world and should be practiced as often as possible",
    "“The work” is being intentional about each block – the product will fall into place if the setup is there – each task is “make a block for x”",
    "Breaks should be centered around “recharging my battery”, not necessarily something that’s purely mindless",
    "It’s worth it to take the time to get started on all the distinct tasks for the day – another way to think about it is that you should start the day by doing the hardest thing, and the hardest thing is getting started on each task (this sounds right but might be questionable)",
    "Remember to make each day about a single project as often as possible, which means putting off admin tasks and other one-offs to “admin days/blocks” whenever possible",
    "The motivation of knowing that even if it seems like there’s an endless amount to do and you won’t finish in time, if you jump in then you really will finish in time",
    "So often meeting a deadline comes down to the minute – the minutes matter. It’s not something you turn on and off, it’s the mindset you go through each day with – not frenzied, but aware that each moment matters",
    "Hold yourself to a higher standard — that doesn’t have to mean being perfect, it just means doing a tiny bit better than your norm",
    "Do the work in a way where it goes smoothly, which means thinking ahead to future steps",
    "When I set out to do something (e.g. getting a coding task done), do it in a way that I know for sure I can complete the entire task as quickly as possible — things that drag on are huge killers of progress. And if I start something thinking it will be quick but it turns out to start to drag on, then quickly pivot to a way to get it done right away — I need to completely finish an important task with each block, and if I can’t do it that block, then I need to figure out how to do it by the next block",
    "Before adding new things I should do to improve the day, I need to get consistent at avoiding the old traps I fall into",
    "Write out what I need to get done before the end of the day, and do those first",
    "99% of the time when I think I don’t need to be intentional about blocks, I actually do need to prioritize things, and I lose so much time when I don’t. I never have to do the task right away, but I at least have to break it down",
    "Identify the tasks that are easier and should be done at night as I lose steam",
    "Just like the learning about how there’s so many fewer blocks in the day than it feels like, every detour/distraction that seems small is actually taking a critical piece of the day",
    "Sometimes it feels like I’m not in the right headspace to do a task, but it’s really just about my brain not having anything to “grab onto” yet in the task – if I sit with it and find one small thing that grabs me, then it’s like a switch flips and the task comes easily (learned from feeling like I needed a break from writing but then sitting with it, looking over the draft, and then the writing coming easily)",
    "It might be best to shoot for a 5% success rate on the least important things (assuming I have to do them at all), spending hardly any time on them",
    "Do a one-minute version of tasks whenever possible",
    "It feels like I don’t need to cut things, but that’s because the feedback is way delayed, if it ever comes at all – in reality I need to cut things all the time, no exceptions, and I’ll see the better results",
    "It feels so much better to simplify your life",
    "Don’t try to manufacture the outcome, just do what’s right each moment",
    "Fast decisions with occasional mistakes is the way to learn better and make progress more quickly",
    "Don’t forget the most common “needs improvement” entries",
    "It’s easy to avoid distractions when something makes me feel like I have a time limit",
    "Before you can accept mistakes (and have everything else that comes with self-respect) you need to fully embrace where you are in life, because your mistakes are what got you there",
    "How you do any day is how you do every day (this obviously isn’t true but is still a motivating way to think about the day)",
    "Don’t hold back – go all in right now",
    "Decide what an unacceptable timeline/pace would be, and then get clear on how to make it impossible for that to happen",
    "When talking, don’t worry about getting the full, perfect version right away – instead, say whatever you can clearly say that’s closest to the full version, then confidently work towards the full version, using what you’re saying as a way to work out what you mean",
    "It’s almost always worth it to take 15 minutes to write out my thinking for any non-trivial initiative",
    "For any task that makes sense to put a time limit on (including many sub-tasks within deep work tasks, even if I’m not putting a time limit on deep work as a whole), I should imagine that I told someone important to me that I’d make sure to have it done in a specific amount of time",
    "How you do things matters – take a second to check if there’s a slightly better approach, and then go all-in on executing quickly and well",
    "In so many cases if you just decide that something isn’t going to bother you then like magic it’s not an issue (learned from not being bothered by the snow when going on a run once I decided it didn’t matter)",
    "If something can be done in an hour or less and has a decent chance of having a big upside, do it right away instead of taking any time to think it over",
    "Keep working and see what happens – I assume I won’t be able to work as well, and it feels like that’s true, but that could self-fulfilling instead of something that has to be true",
    "What’s getting in the way of making more rapid progress – what’s different from the ideal state I’m imagining, and what do I need to change to get it there?",
    "I’ve trained myself to think I always say the wrong thing because I look back and think about all the ways it should have been better, but people don’t notice that, and if I speak confidently it shows that even if it’s flawed it’s still great",
    "“When we praise kids for the process they engage in, for their hard work, their strategies, their focus, their perseverance – they learn challenge-seeking. They learn that resilience.” –Carol Dweck",
    "When I’m starting to feel like I should rest my eyes, I should instead keep knocking down tasks until there’s nothing left that I’m in the headspace for, and then rest my eyes at that point – basically, push back resting my eyes as much as possible",
    "Sometimes I can prepare for what I think will be the next task after a break, but then after the break I feel too frazzled to do a good job on that task and end up not rushing to return to it, so I should have a mindless task ready to return to in addition to the main one",
    "I can recognize mistakes and feel bad about them and carry them with me without defining myself by it",
    "I need to notice if I’m not moving fast enough in the moment instead of only at the end of the day, which might include working on a completely different task",
    "I should have an allergy to not keeping pace, identifying what needs to change when that happens",
    "How can I grow in this moment?",
    "Plan out the day/block where there’s no excuse of not getting through everything on the list…but also knowing you’ll have to rush to guarantee that (if something isn’t coming quickly, move on to something else)",
    "Am I doing my best right now? If not, why not?",
    "One of the most common ways to make the day better is by stopping for just a few seconds to get organized",
];
